#tools_left {
  position: absolute;
  border-right: none;
  width: var(--x12);
  top: var(--x8);
  bottom: 0;
  left: 0;
  z-index: 4;
}

.tool_button {
  height: var(--x12);
  width: var(--x12);
  cursor: pointer;
  line-height: 60px;
  text-align: center;
  transform: scale(0.8);
}

.inverted .tool_button svg {
  fill: var(--z13);
}

.tool_button:hover {
  transform: scale(1);
}

.tool_button:hover svg {
  fill: var(--z13);
}

.tool_button.current {
  transform: scale(1.4);
}

.tool_button svg {
  vertical-align: center;
  fill: var(--z5);
}

.tool_button.current svg {
  fill: var(--d8);
}

.tool_button.current svg {
  fill: var(--d7);
}


.tool_button.disabled svg {
  background-color: #aaa;
  cursor: not-allowed;
  fill: var(--z3);
  pointer-events: none;
}

#tool_rect svg {
  position: relative;
  top: -1px;
  left:  1px;
}

#tool_ellipse svg {
  position: relative;
  top: 2px;
  left: 1px;
}