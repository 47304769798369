table.jPicker {
    border-collapse: collapse;
    margin-top: var(--x4);
}

table.jPicker td {
    padding:  var(--x1);
}

.jPicker .Icon {
    display: inline-block;
    height: var(--x6);
    position: relative;
    text-align: left;
    width: var(--x6);
}
.jPicker .Icon span.Color, .jPicker .Icon span.Alpha {
    background-position: 2px 2px;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%}
.jPicker .Icon span.Image {
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%}
.jPicker.Container {
    z-index: 10;
}

.jPicker .Move {
    background-color: var(--z12);
    border-color: var(--z15) var(--z6) var(--z6) var(--z15);
    border-style: solid;
    border-width: 1px;
    cursor: move;
    height: 12px;
    padding: 0;
}
.jPicker .Title {
    display:  none;
}

.jPicker .Map {
  border:  solid var(--z1) 1px;
  height: 256px;
  width: 256px;
  cursor: crosshair;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.jPicker .Bar {
    border:  solid var(--z1) 1px;
    cursor: n-resize;
    height: 260px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 24px;
    height: 256px;
    width: 20px;
}

.jPicker .Map .Map1, 
.jPicker .Map .Map2, 
.jPicker .Map .Map3, 
.jPicker .Bar .Map1, 
.jPicker .Bar .Map2, 
.jPicker .Bar .Map3, 
.jPicker .Bar .Map4, 
.jPicker .Bar .Map5, 
.jPicker .Bar .Map6 {
    background-color: transparent;
    background-image: none;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
}

.jPicker .Map .Map1, 
.jPicker .Map .Map2, 
.jPicker .Map .Map3 {
    height: 2596px;
    width: 256px;
}
.jPicker .Bar .Map1, .jPicker .Bar .Map2, .jPicker .Bar .Map3, .jPicker .Bar .Map4 {
    height: 3896px;
    width: 20px;
}
.jPicker .Bar .Map5, .jPicker .Bar .Map6 {
    height: 256px;
    width: 20px;
}
.jPicker .Map .Map1, .jPicker .Map .Map2, .jPicker .Bar .Map6 {
    background-repeat: no-repeat;
}
.jPicker .Map .Map3, .jPicker .Bar .Map5 {
    background-repeat: repeat;
}
.jPicker .Bar .Map1, .jPicker .Bar .Map2, .jPicker .Bar .Map3, .jPicker .Bar .Map4 {
    background-repeat: repeat-x;
}
.jPicker .Map .Arrow {
    display: block;
    position: absolute;
}
.jPicker .Bar .Arrow {
    display: block;
    left: 0;
    position: absolute;
}
.jPicker .Preview {
    font-size: 9px;
    text-align: center;
}
.jPicker .Preview div.bgt  {
    height: 62px;
    margin: 0 auto;
    padding: 0;
    width: 62px;
}

.jPicker .Preview div span {
    border: 1px solid var(--z0);
    display: block;
    height: 30px;
    margin: 0 auto;
    padding: 0;
    width: 60px;
}
.jPicker .Preview .Active {
    border-bottom-width: 0;
}
.jPicker .Preview .Current {
    border-top-width: 0;
    cursor: pointer;
}
.jPicker .Button {
    text-align: center;
    width: 115px;
}
.jPicker .Button input {
    width: 100px;
    cursor: pointer;
}

.jPicker td.Radio {
    margin: 0;
    padding: 0 0 0 var(--x2);
    width: var(--x8);
    white-space: nowrap;
}
.jPicker td.Radio input {
    margin: 0 var(--x1) 0 0;
    padding: 0;
    cursor: pointer;
}
.jPicker td.Text {
    font-size: 12px!important;
    height: 22px;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 70px;
    white-space: nowrap;
}
.jPicker tr.Hex td.Text {
    width: 100px;
}

.jPicker tr.Hex td.Text label {
    margin-left: var(--x4);
}

.jPicker tr.Hex td.Text span {
    width: 100px;
    color: #333;
}

.jPicker td.Text input {
    background-color: var(--z15);
    height: var(--x4);
    margin: 0 0 0 var(--x1);;
    text-align: left;
    width: var(--x8);
    color: var(--z15);
}

#color_picker input[type=text], #color_picker  input[type=number] {
  -webkit-appearance:  none;
  width: 30px;
  background: var(--z0);
  border: none;
  border-radius:  var(--x1);
}

#color_picker input[type=radio] {
    position: relative;
    top:  2px;
}

#color_picker .jPicker tr.Hex td.Text input.Hex {
    width: var(--x12);
    display: inline-block;
    float: none;
}

.jPicker tr.Hex td.Text input.AHex {
    width: var(--x5);
    display: none;
}
.jPicker .Grid {
    text-align: center;
    float: right;
    width: 108px;
}
.jPicker .Grid span.QuickColor {
    cursor: url(../images/eyedropper.svg) 0 23, crosshair;
    background-repeat:  no-repeat;
    display: inline-block;
    height: var(--x4);
    line-height: var(--x4);
    margin: 0;
    padding: 0;
    width: var(--x4);
}

.jPicker .Grid span.QuickColor:hover {
    position: relative;
    z-index: 10;
    outline:  solid var(--z0) 1px;
}

.jPicker td  {
  vertical-align:  top;
}
.jPicker td.colorsquare  {
  width:  275px;
}

.jPicker .prev_div  {
  margin-top:  -15px;
}

.jPicker .actions {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.jPicker .actions .Ok{
  position: absolute;
  top: 0;
  right: 0px;
}

.jPicker .actions .Cancel{
  position: absolute;
  top: 0;
  left: 0px;
}

.jPicker .color_preview {
  width: 62px;
  margin: 0 auto;
}
