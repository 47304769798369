.align_buttons {
  margin-bottom: var(--x2);
}

.align_button,
.align_button * {
  transition: var(--transition);
}

.align_button:hover,
.align_button:hover * {
  transition: none;
}

.align_button {
  float: left;
  height: var(--x13);
  width: var(--x13);
  line-height: var(--x16);
  text-align: center;
  cursor: pointer;
  position: relative;
}

.align_button:hover {
  background-color: var(--z0);
  border-color: var(--z3);
}

.align_button svg {
  fill: var(--z10);
}

.align_button:hover svg {
  fill: var(--z15);
  transition: none;
}

.align_button:nth-child(1) {border-radius: var(--x1) 0 0 0;}
.align_button:nth-child(3) {border-radius: 0 var(--x1) 0 0;}
.align_button:nth-child(4) {border-radius: 0 0 0 var(--x1);}
.align_button:nth-child(6) {border-radius: 0 0 var(--x1) 0;}

.align_buttons .push_button {
  display: block;
  float: left;
}