#color_tools #tool_fill .color_block > div {
  position: absolute;
  top: 0;
  left: 0;
}

#color_tools #tool_fill .color_block #fill_bg,  #color_tools #tool_stroke .color_block #stroke_bg {
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
}

#stroke_color:after {
  content: '';
  position: absolute;
  display: block;
  left: var(--x1);
  right: var(--x1);
  top: var(--x1);
  bottom: var(--x1);
  background: var(--z1);
  box-shadow: 0 0 0 1px var(--z13);
}

#tool_switch {
  cursor: pointer;
  width: 11px;
  height: 11px;
  position: absolute;
  top: -5px;
  right: 0;
  cursor: nwse-resize
}

#tool_switch svg {
  fill: var(--z6);
}

#tool_switch:hover svg {
    fill: var(--z15);
}

#stroke_color:hover:after {
  box-shadow: 0 0 0 1px var(--z15);
}

.color_block {
  width: var(--x5);
  height: var(--x5);
  overflow: hidden;
  border: solid var(--z12) 1px;
  cursor:  pointer;
}

.color_block svg {
  width: 24px;
  height: 24px;
}

 #tool_stroke .color_block > div {
  position: absolute;
  bottom: 0;
  right: 0;
}

#color_tools {
  position: relative;
  width: 48px;
  height: 48px;
  margin: 12px 6px 0 6px;
}

#color_tools {
  width: auto;
  height: auto;
}

#tool_fill {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

#tool_fill.active, 
#tool_stroke.active {
  z-index: 2;
}

#tool_stroke {
  top: 14px;
  left: 14px;
}

#tool_fill, #tool_stroke, #tool_canvas {
  box-shadow: 0 0 0 1px var(--z0);
  position: absolute;
}

#tool_canvas .color_block {
  width: 58px;
  height: 38px;
}

#tool_canvas .color_block svg {
  width: auto;
  height: auto;
}

