
#dialog_box {
  display: none;
}

#dialog_box_overlay {
  background: var(--z0);
  opacity: .5;
  height: 100%;
  left:0;
  position:absolute;
  top:0;
  width:100%;
  z-index: 6;
}

#dialog_content {
  height: 95px;
  padding: 32px;
  overflow: auto;
  text-align: left;
  font-size: 16px;
}

#dialog_content h4 {
  margin: 0;
}


#dialog_buttons input:last-child {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

#dialog_buttons input:first-child {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

#dialog_content.prompt {
  height: 75px;
}

#dialog_content p {
  line-height: 1.3em;
}

#dialog_container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  position:fixed;
  z-index:50001;
  background: var(--z15);
}


#dialog_container, #dialog_content {
  border-radius: 3px;
}

#dialog_buttons input[type=text] {
  width: 90%;
  display: block;
  margin: 0 0 5px 11px;
}

#dialog_buttons input[type=button] {
  margin: 0 1em;
}
