#group_title {display: none;}

#tool_bold,   #tool_italic { 
  font: bold 20px/35px serif;
  text-align: center;
  position: absolute;
  padding: 0 0 0 0;
  color: var(--z13);
  background: transparent;
  border: none;
  margin: 0;
  width: 50%;
  top: var(--x8);
  line-height: var(--x10);
  bottom: 0;
  border-top: solid var(--z1) 2px;
  cursor:  pointer;
}

#tool_bold {
  right: 0;
}

#tool_italic {
  border-right: solid var(--z1) 2px;
  line-height: var(--x9);
  left: 0;
  font-weight: bold;
  font-style: italic;
  font-size: 24px;
}

#tool_bold:hover,   #tool_italic:hover {
  color: var(--z15);
  background-color:  var(--z3);
}

#tool_bold.disabled,
#tool_italic.disabled {
  opacity: 1;
  color: var(--z4);
  pointer-events: none;
}

#tool_bold.active,   #tool_italic.active {
  color: #50A0FF;
}

#preview_font {
  color: var(--z15);
  font-size: 2em;
  line-height: 80px;
  padding: 0 var(--x2);
}

#preview_font + .caret {
  right: var(--x2);
}

.draginput:hover #preview_font:after {
  border-right-color: var(--z3);
  background: linear-gradient(to right, rgba(0,0,0,0), var(--z3));
}

#preview_font:after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  bottom: 3px;
  width: 15px;
  border-right: solid var(--z2) 10px;
  background: linear-gradient(to right, rgba(0,0,0,0), var(--z2));
}

#textpath-panel {
  display: none;
}

.text-path #textpath-panel {
  display: block;
}