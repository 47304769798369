#workarea > svg {
  display: none;
}

.loading #svgcanvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#svgcanvas:after {
  transition:  opacity var(--transition-duration) 300ms;
  opacity: 0;
}

.loading #svgcanvas:after {
  position: absolute;
  content: 'Loading';
  font-size:  13px;
  font-weight: 600;
  line-height: var(--x6);
  padding:  0 var(--x2);
  background:  var(--z2);
  color:  var(--z10);
  z-index: 100;
  opacity: 1;
  border-radius: var(--x1);
}

.loading #workarea > svg {
  display: block;
  position: absolute;
  background:  white;
  top:  50%;
  left:  50%;
  transform:  translate(-50%, -50%);
  opacity: 0.7;
}




