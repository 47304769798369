/* Comment to prevent wrong concat */

body {
  background: var(--z1);
  font: 14px/120% -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

body.inverted {
  background: var(--z2);
}

::selection {background: #000; color: #fff; /* Safari */}
::-moz-selection {background: #000; color: #fff; /* Firefox */}

html, body {
  overscroll-behavior-x: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
    width: var(--x1);
    height: var(--x1);
    background: transparent;
    position: absolute;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--z3);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.inverted ::-webkit-scrollbar-thumb {
    background: var(--z3);
}


div#font-selector {
  width:140px;
  height:300px;
  overflow:auto;
  margin:0 auto;
  position:absolute;
  top:27px;
  right:0;
  border:1px solid black;
  padding:10px;
  display:none;
  background-color:white;
  z-index: 10;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.7);
}

div#font-selector img {
  width: 100%;
}

div#font-selector .font-item {
  border-bottom: solid #ddd 1px;
  padding: 5px 10px;
  margin: 0 -10px;
}

div#font-selector .font-item:hover {
  background-color: #eee;
}

 .dropdown_set input[type=text],  .dropdown_set input[type=number] {
  width: 50px;
}

 input[type=text].wide,  input[type=number].wide {width: 110px;}
 input[type=text].tuco,  input[type=number].tuco {width: 150px;}

 input[type=submit],  input[type=button], button {
  background: var(--d7);
  color: var(--z15);
  border-radius: var(--x1);
  padding: var(--x2) var(--x4);
  border: none;
  line-height: 140%;
  font-size: 16px;
  font-weight: 550;
  cursor: pointer;
}

input[type=submit]:active,
input[type=button]:active,
button:active {
  box-shadow: 0 0 0 var(--x1) var(--d14);
  outline: none;
}

input[type=submit]:focus,
input[type=button]:focus,
button:focus {
  outline: none;
}

 button.cancel,  input.Cancel,  input.cancel,  input.jGraduate_Cancel, button.cancel {
  -webkit-appearance: none;
  background-color: var(--z12);
  color: var(--z3);
  margin: 0;
}

 button.cancel:hover,  input.Cancel:hover,  input.cancel:hover,  input.jGraduate_Cancel:hover, button.cancel:hover {
  background-color: var(--z13);
}

 button.warning {
  -webkit-appearance: none;
  background-color: var(--b6);
  color: var(--z15);
  margin: 0;
}

 button.warning:hover {
  background-color: var(--b7);
 }

#svgcontent {
  overflow: hidden;
}

.wireframe #svgcontent * {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
  stroke-opacity: 1.0;
  stroke-dasharray: 0;
  opacity: 1;
  pointer-events: stroke;
  vector-effect: non-scaling-stroke;
  filter: none;
}

.wireframe #svgcontent text {
  fill: #000;
  stroke: none;
}

#canvas_panel {
  display: block;
}

 #multiselected_panel .selected_tool {
  vertical-align: 12px;
}

#cur_context_panel {
  position: absolute;
  top: 47px;
  left: 68px;
  line-height: 22px;
  overflow: auto;
  border-bottom: none;
  border-right: none;
  padding-left: 5px;
  font-size: 12px;
  background: black;
  color: #999;
  opacity: 0.5;
  padding: 0 10px;
  border-radius: 0 10px 10px 0;
}

 #cur_context_panel a {
  float: none;
  text-decoration: none;
  color: #fff;
}

 #cur_context_panel a:hover {
  text-decoration: underline;
}

#main_menu li#tool_open,  #main_menu li#tool_import {
  position: relative;
  overflow: hidden;
}

#tool_image {
  overflow: hidden;
}

input[type=file] {
  -webkit-appearance: none;
  position: absolute;
  opacity: 0;
  background: red;
  z-index: 10;
  height: 30px;
  width: 100%;
  transform: translate(-100%, 0);
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.width_label {
  padding-right: 5px;
}

 #tool_bold span,  #tool_italic span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  background: #ccc;
  opacity: 0;
}

 #tools_bottom {
  position: absolute;
  left: var(--x12);
  right: 0;
  bottom: 0;
  height: var(--x10);
  overflow: visible;
}

 ul li.current {
  background-color: #F4E284;
}

.toolbar_button button .svg_icon {
    display: none;
}

.invisible {
  visibility: none;
}

/* For modern browsers */
.clearfix:before,
.clearfix:after {
    content:"";
    display:table;
}

.clearfix:after {
    clear:both;
}

input[readonly=readonly]:focus {
  box-shadow: none;
}

#color_canvas_tools, #fill_bg, #stroke_bg {
  background: var(--z15) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMAQMAAABsu86kAAAAA3NCSVQICAjb4U/gAAAABlBMVEXu7u7///8o06qaAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFXRFWHRDcmVhdGlvbiBUaW1lADcvMjIvMTL7FNdCAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M1cbXjNgAAABFJREFUCJljYP7AgIb+MKAhAM8/C5vWL6zSAAAAAElFTkSuQmCC) top left repeat;
}

#color_canvas_tools {
  width: 60px;
  height: 40px;
  margin: 23px 5px 5px 5px;
  position: relative;
  overflow: hidden;
}

#color_canvas_tools  {
  display: block;
}

#stroke_style_label {
  font-size: 30px;
  margin-top: 33px;
  letter-spacing: -1px;
}

.stroke_tool .caret {
  top: 60%;
}
