@use '@angular/material' as mat;
@include mat.core();

$mapper-palette: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #80cbc4,
  300: #06beb8,
  400: #26a69a,
  500: #009688,
  600: #00897b,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);
$my-primary: mat.m2-define-palette($mapper-palette, 300);
$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: mat.m2-define-palette($mapper-palette),
    ),
  )
);

@include mat.all-component-themes($my-theme);


$primary: #06beb8;
$secondary: #25283D;


@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap-icons/font/bootstrap-icons.css";
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

$color-primary: #06beb8;
$color-secondary: #25283D;
$color-accent: #f42171;
$radius: 7px;
$header-height: 48px;
$body-bg: #f9f9f9;

:root {
  --bs-brand-primary: #06beb8;
  --mdc-checkbox-state-layer-size: 22px;
}

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: $body-bg !important;
}

* {
  font-family:  Montserrat , "Helvetica Neue", sans-serif !important;
}

a {
  color: $color-secondary;
}

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.color-accent {
  color: $color-accent;
}

.color-grey {
 color: #868686;
}

body { margin: 0;  }
body, .mat-body, .mat-body-1, .mat-typography {
  font-family:  Montserrat , "Helvetica Neue", sans-serif !important;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Montserrat, "Helvetica Neue", sans-serif !important;
  letter-spacing: normal;
}

.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
/* You can add global styles to this file, and also import other style files */


.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  &-header {
    position: relative;
    padding: 5px;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);

    &-logo {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: $header-height;
      letter-spacing: -0.03em;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      text-decoration: none;
      color: $color-secondary;
      &:hover {
        text-decoration: none;
        color: lighten($color-secondary, 10%)
      }
    }
  }

  &-content {
    position: relative;
    flex: 1;
    overflow-y: auto;
  }

  &-progress-loader {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
  }
}

.page {
  &-header {
    height: 80px;

    background:
      linear-gradient(46deg, rgba(37, 40, 61, 0.8), rgba(37, 40, 61, 0.4) 70.71%),
      linear-gradient(276deg, rgba(6, 190, 184, 0.8), rgba(6, 190, 184, 0.4) 70.71%),
      linear-gradient(36deg, rgba(37, 40, 61, 0.8), rgba(37, 40, 61, 0.4) 70.71%);
    //background-color: #25283D;
    &-title {
      color: white;
      font-family:  Montserrat;
      font-size: 24px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 80px  !important;
      text-align: left !important;
    }
  }
}

.page-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  &-block {
    width: 430px;
    padding: 30px 20px;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
  }
}

.cesium-widget-credits,
.cesium-viewer-toolbar,
.cesium-infoBox,
.cesium-viewer-fullscreenContainer
{ display: none !important; }

.cesium-screenshot-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.plan-preview {
  min-height: 60vh;
  height: 400px;
  width: 100%;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 46px -20px rgb(0 0 0 / 20%);

  &-pdf {
    min-height: 20vh;
    height: 200px;
    width: 100%;
    border: 1px solid #e8e8e8;
    box-shadow: 0 0 46px -20px rgb(0 0 0 / 20%);

  }

  &-tag {
    display: inline-block;
    width: 100%;
    color: $color-primary;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;

    .plan-preview-block-validate & {
      color: white;
    }
  }

  &-name {
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    display: inline-block;

    .plan-preview-block-validate & {
      color: white;
    }
  }

  &-column {
    &-1 {
      width: 18%;
    }
    &-2 {
      width: 18%;
    }
    &-3 {
      width: 56%;
    }
    &-4 {
      width: 9%;
    }
  }

  &-block {
    border: 1px solid #e8e8e8;
    cursor: pointer;

    display: flex;
    justify-content: start;
    align-items: flex-end;

    position: relative;

    &:hover {
      border-color: $color-primary;
    }

    &-1 {
      height: 16%;
    }
    &-2 {
      height: 60%;
    }
    &-3 {
      height: 60%;
    }
    &-4 {
      height: 30%;
    }
    &-5 {
      flex: 1;
    }
    &-6 {
      height: 35%;
    }
    &-7 {
      height: 50%;
    }
    &-8 {
      height: 50%;
    }
    &-9 {
      height: 70%;
    }
    &-10 {
      height: 30%;
    }

    &-validate {
      background-color: $color-primary;
      //border-color: white;

      &::after {
        content: "\f26e";
        position: absolute;
        top: 15px;
        right: -1px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 60px;
        font-family: bootstrap-icons !important;
      }
    }
  }
}


.page-label {
  width: 100vw;
  height: calc(100vh - 48px);
  overflow: hidden;
  display: flex;
  flex-direction: row;

  position: relative;

  .label-workarea {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $body-bg;
    border: 20px solid transparent;
  }

  .label-panel {
    min-width: 350px;
    width: 350px;
    background-color: white;
    border-right: 1px solid #e8e8e8;
  }

  .label-loader {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: $body-bg;
    &-el {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.page-map {
  width: 100vw;
  height: calc(100vh - 48px);
  overflow: hidden;
  display: flex;
  flex-direction: row;

  &-panel {
    min-width: 350px;
    width: 350px;
    background-color: white;
    border-right: 1px solid #e8e8e8;

    overflow-y: auto;
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

.reserved-area {
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: 0;
  width: 26.31%;
  height: 14.28%;
  background: rgba(255,255,255,0.75);
  z-index: 100;
  top: 0;
  border: 3px solid black;
  cursor: move;

  &-1 {
    top: 0;
  }
  &-2 {
    top: 0;
  }
}

.title-area {
  position: absolute;
  display: flex;
  align-items: flex-end;
  background: transparent;
  z-index: 100;
  cursor: move;
  font-weight: bold;
  &-1 {
    top: 0;
  }
  &-2 {
    top: 0;
  }
}

.reserved-area-custom-background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

}

.reserved-area-custom-svg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 110;
  pointer-events: none;
}


.zoom-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 26.31%;
  height: 35.71%;
  background: rgba($color-primary, 0.75);
  z-index: 20;
  border: 3px solid black;
  cursor: move;

  &-1 {
    //top: 28.56%;
  }
  &-2 {
    //top: 64.27%;
  }

  &.focused {
    border-style: dashed;
  }
}

.drop-box {
  user-select: none;
  height: 50%;
  width: 26.31%;
  border: 3px dashed $color-primary;
  position: absolute;

  z-index: 5;
  &-top-left {
    top: 0;
    left: 0;
  }
  &-bottom-left {
    bottom: 0;
    left: 0;
  }
  &-bottom-right {
    bottom: 0;
    right: 0;
  }
  &-top-right {
    top: 0;
    right: 0;
  }
}



#workarea {
  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
}


.pm-tag {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: white;
  background: #25283D;
  display: inline-block;
}

.zone {
  margin-bottom: 40px;


  &-header {
    display: flex;
    justify-content: space-between;
    opacity: .6;

    cursor: pointer;
    border-bottom: 1px solid grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.12em;
    text-align: left;

    &-insee {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.12em;
    }
    &-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.12em;
    }

    &-opened {
      opacity: 1;
      border-bottom-color: black;
    }

    &-toggle {
      color: inherit;
      padding: 5px;
      background: rgba(37, 40, 61, 0.1);
      border-radius: 5px 5px 0px 0px;
      &:hover {
        background: rgba(37, 40, 61, 0.1);
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: row;

    &-plan {
      width: 19%;
      background-color: white;
      padding: 20px;
      min-width: 240px;
      border-radius: 5px;
      //height: 150px;
      position: relative;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);

      justify-content: space-between;
      display: flex;
      flex-direction: column;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid $color-primary;
      }

      &-city {
        color: #3bbeb8;
        text-transform: uppercase;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.05em;
        margin-bottom: 5px;
      }
      &-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
      &-author {
        font-size: 12px;
        color: #b7b7b7;
      }
      &-actions {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        color: lightgray;
        font-size: 23px;
        padding: 12px 5px;

        &:hover {
          color: $color-secondary;
          background-color: rgba($color-secondary, .03);
        }
      }
    }
  }
}

.pm-card {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
  border-radius: $radius;
  &-hover {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $color-primary;
    }
  }
}

.pm-btn {
  padding: var(--x2) var(--x4);
  background: #eaeaec;
  font-weight: bold;
  border-radius: $radius;
  color: #181717;

  &:hover {
    background-color: darken(#eaeaec, 20%);
  }

  &:disabled {
    opacity: .7;
    cursor: not-allowed;
  }

  &-success, &-primary {
    background-color: $color-primary;
    color: white;
    &:hover {
      background-color: darken($color-primary, 15%);
    }
  }

  &-secondary {
    background: $color-secondary;
    color: white;
    &:hover {
      background-color: lighten($color-secondary, 15%);
    }
  }

  &-accent {
    background: $color-accent;
    color: white;
    &:hover {
      background-color: lighten($color-accent, 15%);
    }
  }

  &-group {
    button {
      &:hover {
        background-color: darken($color-primary, 15%);
        color: white;

      }
    }
  }


}

a.pm-btn {
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}

.pm-collapse {
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-radius: $radius;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
  }

  &-content {
    border-top: 1px solid #e8e8e8;
    display: none;
    &-opened {
      display: block;
    }
  }
}


.pm-zoom {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: $radius;

  &-name {
    border-radius: $radius;
    background-color: #e8e8e8;
    font-size: 10px;
    font-weight: 700;
    padding: 0px 10px;
    position: absolute;
    top: -9px;
    left: 10px;

  }

  &:hover {
    border-color: $color-secondary;
    .pm-zoom-name {
      background-color: $color-secondary;
      color: white;
    }
  }
}

.pm-layer {
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-radius: $radius;
  font-size: 14px;
  &:hover {
    border-color: $color-secondary;;
  }
}

.pm-panel {

  &-content {
    flex: 1;
    overflow-y: auto;
  }

  &-actions {
    border-top: 1px solid #e8e8e8;
  }
}

.pm-search {
  .mat-form-field-wrapper {
    padding: 0;
  }
}

.pm-btn-nostyle {
  background-color: transparent;
  color: inherit;
  padding: initial;
  margin: initial;

  &:hover {
    background-color: transparent;
  }
}

.wrong-navigator {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  margin: 20px;
  padding: 20px;
  border-radius: $radius;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
  background-color: #fa983a;
  max-width: 600px;
}

.pm-titles {

  position: relative;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: $radius;

  &-name {
    border-radius: $radius;
    background-color: #e8e8e8;
    font-size: 10px;
    font-weight: 700;
    padding: 0px 10px;
    position: absolute;
    top: -9px;
    left: 10px;

  }

  &:hover {
    border-color: $color-secondary;
    .pm-zoom-name {
      background-color: $color-secondary;
      color: white;
    }
  }

}

///////// OVERRIDE MATERIAL

app-steps {
  .mat-button-toggle-label-content,
  .mat-button-toggle-button span {
    line-height: 29px !important;
  }

  .mat-button-toggle-group,
  .mat-button-toggle-button {
    height: 37px;
  }

  .mat-button-toggle-button {
    &:hover {
      background-color: var(--bs-grey-200);
    }
  }

  .step-true {
    .mat-button-toggle-button {
      background-color: $color-primary;
      color: white;
    }
  }

  .step-false {
    .mat-button-toggle-button {
      background-color: var(--bs-danger);
      color: white;
    }
  }
}

.mat-menu-item {
  font-weight: 600;
}

.mat-tooltip-panel {
  font-weight: 600;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: -0.25em !important;
}

#map-toolbar {
  background-color: white;
  border: $radius;
  padding: 15px;
  position: absolute;
  z-index: 10;
  display: none;
}


.cartouche {
  display: flex;
  width: 100%;
  height: calc(100% - 48px);
  overflow-x: hidden;
  &-add {
    font-size: 14px;
    padding: 2px 10px;
  }

  &-content {
    width: 60%;
  }

  &-iframe {
    width: 40%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &-logo {
    display: inline-block;
    padding: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 7px;

    img {
      max-width: 200px;
      height: auto;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid #e8e8e8;
}

.cursor-pointer {
  cursor: pointer;
}


.map-north-arrow {
  position: absolute;
  top: 0;
  right: 0;
  &-3d {
    width: 50px;
    height: 50px;
  }

  width: 25px;
  height: 25px;
  z-index: 10;
  img {
    width: 100%;
    height: auto;
  }
}


.map-echelle {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  font-size:10px;
  background: rgba(255,255,250, .5);
  padding: 2px 7.5px;

  &-3d {
    font-size: 14px;
    padding: 4px 15px;
  }

  &_label {
    display: inline-block;
  }

  &_bar {
    content: '';
    display: inline-block;
    margin-right: 5px;
    height: 3.5px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
  }
}

.map-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: white;
  z-index: 10;
  cursor: pointer;
  //pointer-events: none;
  font-size: 22px;
  font-weight: bold;
  text-shadow: 3px 3px 6px black;
}

.canvas-print {
  width: 1500px;
  height: 1500px;
  transform: scale(0.503467);
  position: absolute;
  left: -9999px;
  z-index: 90;
}


:root {
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-vertical-padding: 14px;

  --mat-select-trigger-text-line-height: 14px;
  --mat-select-trigger-text-size: 14px;

  --mdc-outlined-text-field-outline-color: rgba(0,0,0,.12);

  --mat-form-field-container-height: 50px;
}

.hide-form-field-helper .mat-mdc-form-field-subscript-wrapper{
  display: none;
}
.force-label-visibility label {
  width: 64px !important;
  max-width: 64px !important;
  background: white;
}


.radio-group {
  display: flex;
  gap: 10px;

  .mdc-form-field.mat-internal-form-field {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 5px;
  }

  .mat-mdc-radio-checked {
    .mdc-form-field.mat-internal-form-field {
      border-color: $color-primary;
    }
  }
}
