#rulers > div {
  position: absolute;
  background: var(--z2);
  z-index: 1;
  overflow: hidden;
  -webkit-font-smoothing: none;
}

.inverted #rulers > div {
  background: var(--z1);
}

#rulers #ruler_corner {
  top: var(--x8);
  left: var(--x12);
  width: var(--x4);
  height: var(--x4);
  z-index: 2;
  border-radius: var(--x2) 0 0 0;
}

#ruler_x {
  height: var(--x4);
  top: var(--x8);
  left: var(--x12);
  right: calc(var(--panel-width) + var(--x4));;
  overflow: hidden;
  border-radius: var(--x2);
}

#ruler_y {
  width: var(--x4);
  top: var(--x8);
  left: var(--x12);
  bottom: var(--x10);
  overflow: hidden;
  border-radius: var(--x2);
}

#ruler_x > div, 
#ruler_y > div {
  overflow: hidden;
}
