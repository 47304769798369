
 #palette {
  display: block;
  position: absolute;
  z-index: 2;
  left: 10px;
  bottom: 5px;
  width: 410px;
  right: 145px;
  height: 30px;
}

 .palette_item {
  height: 20%;
  width: 5.2%;
  float: left;
  cursor: url(../images/eyedropper.svg) 0 16, crosshair;
}

.palette_item.transparent, .palette_item.white, .palette_item.black {
  background: #fff;
  position: absolute;
  width: 10px;
  height: 10px;
  left: -10px;
  top: 0;
}

.palette_item.transparent {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAVdEVYdENyZWF0aW9uIFRpbWUAOC8yMi8xMg1cp6sAAABESURBVBiVjdBBCgAgCETRr/e/87SJKFPLnfIcQRMIwMBIShIAvgZzoSrfkzrs8WyF1+kX9r3p8AE7nL7kSpSshN+JEQ8GixkF0mSevgAAAABJRU5ErkJggg==);
}

.palette_item.black {
  background: #000;
  top: 10px;
}

.palette_item.white {
  background: #fff;
  top: 20px;
}
